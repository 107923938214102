import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase/config";

export const AuthContext = React.createContext();

export function useAuth() { 
  return useContext(AuthContext);
 
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) {
    return <>Loading...</>;
  }

  return (
  <AuthContext.Provider       value={{
    currentUser
  }}>
    {children}
    </AuthContext.Provider>);
};
